import moment from 'moment';
import NumberStat from 'components/NumberStat/NumberStat';
import Currency from 'components/Currency/Currency';
import React from 'react';
import PropTypes from 'prop-types';
import { budgetStatShape } from 'utils/shapes/stats';

function BillableBudgetUsed({ start, end, stats }) {
  const { invoice_amount_to_date, unbilled } = stats;

  return (
    <p>
      <b>
        {moment(start).format('MMM. Do')} - {moment(end).format('MMM. Do')}:{' '}
      </b>
      <NumberStat value={invoice_amount_to_date.hours} suffix={' hours'} maximumFractionDigits={4} />
      {', '}
      <Currency value={invoice_amount_to_date.dollars} minimumFractionDigits={2} maximumFractionDigits={2} />
      {unbilled && unbilled.hours > 0 && (
        <React.Fragment>
          <br />
          <small>
            <em>
              Note: Caxy has absorbed <NumberStat value={unbilled.hours} suffix={' hours'} maximumFractionDigits={4} />
              {', '}
              <Currency value={unbilled.dollars} minimumFractionDigits={2} maximumFractionDigits={2} />
            </em>
          </small>
        </React.Fragment>
      )}
    </p>
  );
}

BillableBudgetUsed.propTypes = {
  start: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  end: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  stats: PropTypes.shape({
    invoice_amount_to_date: budgetStatShape,
    unbilled: budgetStatShape,
  }),
};

export default BillableBudgetUsed;
