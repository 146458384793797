import orderBy from 'lodash/orderBy';
import NumberStat from 'components/NumberStat/NumberStat';
import PropTypes from 'prop-types';
import { taskBreakdownShape } from 'utils/shapes/stats';
import React from 'react';
import BudgetSummary from 'routes/Reports/routes/BudgetSummary/components/index';

function HoursByTask({ breakdowns }) {
  return (
    <ul>
      {orderBy(BudgetSummary.filterBilledTasks(breakdowns), [(breakdown) => breakdown.budget_stat.hours], ['desc']).map(
        (breakdown) => (
          <li key={breakdown.task_name}>
            <strong>{breakdown.task_name}: </strong>
            <NumberStat
              value={breakdown.budget_stat.hours}
              suffix={' hours'}
              minimumFractionDigits={2}
              maximumFractionDigits={4}
            />
          </li>
        )
      )}
    </ul>
  );
}

HoursByTask.propTypes = {
  breakdowns: PropTypes.arrayOf(taskBreakdownShape),
};

export default HoursByTask;
