import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import DropdownButton from '../../components/DropdownButton';

const ResultWrapper = styled.div.attrs({
  className: 'grid-x grid-margin-x',
})``;

const List = styled.ul`
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  margin: 0;
  list-style: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  & > li {
    padding-top: 0.5rem;
  }

  ul {
    margin: 0;
  }

  li {
    list-style: none;

    a {
      padding: 0.5rem 1rem;
      border-bottom: 1px solid ${(props) => props.theme.cardBorderColor};
      color: ${(props) => props.theme.baseFontColor};
      font-size: 0.8em;

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        color: #ffffff;
        background-color: ${(props) => props.theme.primaryFontColor};
      }
    }
  }
`;

const ClientName = styled.p`
  font-weight: 700;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0;
`;

class ReportSelector extends Component {
  render() {
    return (
      <DropdownButton label={'Reports'}>
        <ResultWrapper>
          <List>
            <li>
              <ClientName>Summary Reports</ClientName>
              <ul>
                <li>
                  <Link to={`/reports/budget-summary`}>Budget Summaries</Link>
                </li>
                <li>
                  <Link to={`/reports/daily-recap`}>Daily Recaps</Link>
                </li>
              </ul>
            </li>
            <li>
              <ClientName>Operations</ClientName>
              <ul>
                <li>
                  <Link to={`/reports/billable-report/monthly`}>Monthly Billable Report</Link>
                </li>
                {/* leaving link empty as a TBD for now */}
                <li>
                  <Link to={``}>TBD CSM REPORT</Link>
                </li>
              </ul>
            </li>
          </List>
        </ResultWrapper>
      </DropdownButton>
    );
  }
}

export default ReportSelector;
