import styled from 'styled-components/macro';

const Filters = styled.div`
  width: 100%;
  height: 61px;
  border-bottom: solid 1px #e1e1e1;

  & > .grid-container {
    height: 100%;

    & > .grid-x {
      height: 100%;
    }
  }
`;

export default Filters;
