import MuiGrid from '@material-ui/core/Grid';
import React from 'react';
import PropTypes from 'prop-types';

export default function LabelAndValue({ label, children, ...props }) {
  return (
    <MuiGrid container wrap='nowrap' justify='space-between' alignItems='center' spacing={2} {...props}>
      <MuiGrid item xs={6}>
        {label}
      </MuiGrid>
      <MuiGrid item>{children}</MuiGrid>
    </MuiGrid>
  );
}

LabelAndValue.propTypes = {
  label: PropTypes.node,
  children: PropTypes.node,
};
