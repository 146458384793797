import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/styles';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'rgba(53,53,53,0.99)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    padding: '.8rem',
  },
  arrow: {
    color: 'rgba(53,53,53,0.99)',
  },
}))(Tooltip);

export default HtmlTooltip;
