import React from 'react';
import styled from 'styled-components/macro';
import { personShape } from 'utils/shapes/person';

const Wrapper = styled.div`
  flex: 0 0 auto;
  width: 46px;
  height: 46px;
  margin-right: 10px;
  overflow: hidden;
  border-radius: 50%;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

function ProfileImage({ person }) {
  const data = {
    avatarUrl: person.avatarUrl || person.avatar_url,
    firstName: person.firstName || person.first_name,
    lastName: person.lastName || person.last_name,
  };

  return (
    <Wrapper>
      {person && <img src={data.avatarUrl} alt={`${data.firstName} ${data.lastName}`} />}
      {!person && <img src={'https://placehold.it/50x50'} alt={'Placeholder profile image'} />}
    </Wrapper>
  );
}

ProfileImage.propTypes = {
  person: personShape,
};

export default ProfileImage;
