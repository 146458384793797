import React, { useState } from 'react';

function useAction(action, clearDataWhileLoading = false) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  // The incoming "action" argument to the hook is NOT performed.
  // It is only stored in the function scope; so that, we can use it when
  // performing the action using the following function
  // This function is returned as the second element in the returned array
  const performAction = async (body = null) => {
    try {
      setLoading(true);
      clearDataWhileLoading && setData(null);
      setError(null);
      const data = await action(body);
      setData(data);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return [{ loading, data, error }, performAction];
}

export default useAction;
