import moment from 'moment';

export function getSemimonthRange(referenceDate) {
  const end = moment(referenceDate);
  const start = end.clone();

  if (end.date() > 15) {
    start.date(16);
    end.endOf('month');
  } else {
    end.date(15);
    start.startOf('month');
  }

  return {
    startDate: start.toISOString(),
    endDate: end.toISOString(),
  };
}

export function getPreviousSemimonthRange(referenceDate) {
  const end = moment(referenceDate);
  const start = end.clone();

  if (end.date() > 15) {
    start.date(1);
    end.date(15);
  } else {
    end.startOf('month').subtract(1, 'month').endOf('month');
    start.startOf('month').subtract(1, 'month').date(16);
  }

  return {
    startDate: start.toISOString(),
    endDate: end.toISOString(),
  };
}

export function getNextSemimonthRange(referenceDate) {
  const end = moment(referenceDate);
  const start = end.clone();

  if (end.date() > 15) {
    start.startOf('month').add(1, 'month');
    end.startOf('month').add(1, 'month').date(15);
  } else {
    start.date(16);
    end.endOf('month');
  }

  return {
    startDate: start.toISOString(),
    endDate: end.toISOString(),
  };
}
