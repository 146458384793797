import { selectData, selectError, selectLoading } from 'routes/DashboardPage/selectors';
import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import startsWith from 'lodash/startsWith';
import toLower from 'lodash/toLower';
import words from 'lodash/words';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect/lib/index';
import styled from 'styled-components/macro';
import { toJS } from 'utils/toJS';
import { list } from '../../actions/project/list';
import DropdownButton from '../../components/DropdownButton';
import { stripApiPath } from '../../utils/helpers';
import { theme } from 'styled-tools';

const Filter = styled.div.attrs({
  className: 'grid-x grid-margin-x',
})`
  padding-bottom: 1rem;
  border-bottom: 1px solid ${(props) => props.theme.cardBorderColor};
`;

const ResultWrapper = styled.div.attrs({
  className: 'grid-x grid-margin-x',
})``;

const List = styled.ul`
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  margin: 0;
  list-style: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  & > li {
    padding-top: 0.5rem;
  }

  ul {
    margin: 0;
  }

  li {
    list-style: none;

    a {
      display: block;
      padding: 0.5rem 1rem;
      border-bottom: 1px solid ${(props) => props.theme.cardBorderColor};
      color: ${(props) => props.theme.baseFontColor};
      font-size: 0.8em;

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        color: #ffffff;
        background-color: ${(props) => props.theme.primaryFontColor};
      }
    }
  }
`;

const ClientName = styled.p`
  font-size: 0.8em;
  font-style: italic;
  color: ${theme('colorGrayWarm')};
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0;
`;

function filterProjects(projects, search) {
  const target = toLower(search);
  return filter(projects, (project) => {
    if (startsWith(toLower(project.name), target) || startsWith(toLower(project.code), target)) {
      return true;
    }

    const nameWords = words(project.name);
    for (let i = 0; i < nameWords.length; i++) {
      if (startsWith(toLower(nameWords[i]), target)) {
        return true;
      }
    }

    return false;
  });
}

class ProjectSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
    };

    this.updateMessage = this.updateMessage.bind(this);
  }

  componentDidMount() {
    if (!this.props.data['hydra:member'] && !this.props.loading) {
      this.props.list();
    }
  }

  updateMessage(event) {
    this.setState({ search: event.target.value });
  }

  render() {
    const { data, loading, baseLinkUri = '/projects/show/', label = 'View a Project' } = this.props;
    const { search } = this.state;

    let filteredData = [];
    if (data['hydra:member']) {
      filteredData = search.length > 0 ? filterProjects(data['hydra:member'], search) : data['hydra:member'];
      filteredData = groupBy(filteredData, (project) => project.client.name);
    }

    return (
      <DropdownButton label={label}>
        <form>
          <Filter>
            <input
              ref={(input) => input && input.focus()}
              className='cell small-12'
              value={search}
              onChange={this.updateMessage}
              type='text'
              placeholder='Search for project...'
            />
          </Filter>
          <ResultWrapper>
            {!!loading && <div className='cell small-12'>Loading...</div>}

            {!loading && filteredData.length === 0 && <div className='cell small-12'>No projects found.</div>}

            {Object.keys(filteredData).length > 0 && (
              <List>
                {Object.keys(filteredData).map((clientName) => (
                  <li key={clientName}>
                    <ClientName>{clientName}</ClientName>
                    <ul>
                      {filteredData[clientName].map((project) => (
                        <li key={project['@id']}>
                          <Link to={`${baseLinkUri}${encodeURIComponent(stripApiPath(project['@id'], '/projects/'))}`}>
                            [{project.code}] {project.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </List>
            )}
          </ResultWrapper>
        </form>
      </DropdownButton>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  data: selectData,
  error: selectError,
  loading: selectLoading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    list: (page) => dispatch(list(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(ProjectSelector));
