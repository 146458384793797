import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components/macro';
import TimeAgo from 'react-timeago';
import { commentShape } from 'utils/shapes/project';
import ProfileImage from 'components/ProfileImage';
import TeamMemberRow from 'components/TeamMemberRow';

const Comment = styled.div`
  flex: 1 1 auto;
`;

const Message = styled.div`
  font-size: 0.9em;
`;

const Author = styled.span`
  font-weight: bold;
`;

const Timestamp = styled.div`
  font-size: 0.7em;
  color: ${(props) => props.theme.colorGrayWarm};
  margin-top: 0.5em;
`;

const outputMessage = (message) => {
  const parts = message.split('\n');

  const output = [];

  parts.forEach((line, index) => {
    output.push(line);
    if (line.length > 0 && index !== parts.length - 1) {
      output.push(<br />);
    }
  });

  return output;
};

function CommentList({ comments }) {
  if (!comments) {
    return null;
  }

  return comments.map((comment) => (
    <TeamMemberRow key={comment['@id']}>
      <ProfileImage person={comment.createdBy.person} />
      <Comment>
        <Message>{outputMessage(comment.message)}</Message>
        <Timestamp>
          <Author>
            {comment.createdBy.person.firstName} {comment.createdBy.person.lastName}
          </Author>{' '}
          &bull; <TimeAgo date={moment(comment.createdAt)} />
        </Timestamp>
      </Comment>
    </TeamMemberRow>
  ));
}

CommentList.propTypes = {
  comments: PropTypes.arrayOf(commentShape),
};

export default CommentList;
