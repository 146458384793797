import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import fetch from 'utils/fetch';
import { BILLING_CYCLE_MONTHLY, DATE_PRESET_MONTH } from '../../../../utils/constants';
import moment from 'moment';

export const FILTERED_STATS_LOADING = 'app/BudgetSummary/FILTERED_STATS_LOADING';
export const FILTERED_STATS_SUCCESS = 'app/BudgetSummary/FILTERED_STATS_SUCCESS';
export const FILTERED_STATS_ERROR = 'app/BudgetSummary/FILTERED_STATS_ERROR';
export const FILTERED_STATS_RESET = 'app/BudgetSummary/FILTERED_STATS_RESET';

export const SET_HOURS_BY_TASK = 'app/BudgetSummary/SET_HOURS_BY_TASK';
export const SET_HOURS_BY_PERSON = 'app/BudgetSummary/SET_HOURS_BY_PERSON';
export const SET_BILLING_CYCLE = 'app/BudgetSummary/SET_BILLING_CYCLE';

export const SET_DATE_FILTERS = 'app/BudgetSummary/SET_DATE_FILTERS';
export const SET_DATE_PRESET = 'app/BudgetSummary/SET_DATE_PRESET';

export function filteredStatsError(error) {
  return { type: FILTERED_STATS_ERROR, payload: error };
}

export function filteredStatsLoading(loading) {
  return { type: FILTERED_STATS_LOADING, payload: loading };
}

export function filteredStatsSuccess(payload) {
  return { type: FILTERED_STATS_SUCCESS, payload };
}

export function filteredStatsReset() {
  return { type: FILTERED_STATS_RESET };
}

export function setHoursByTask(payload) {
  return { type: SET_HOURS_BY_TASK, payload };
}

export function setHoursByPerson(payload) {
  return { type: SET_HOURS_BY_PERSON, payload };
}

export function setBillingCycle(payload) {
  return { type: SET_BILLING_CYCLE, payload };
}

export function setDateFilters(startDate, endDate) {
  return {
    type: SET_DATE_FILTERS,
    payload: { startDate, endDate },
  };
}

export function setDatePreset(preset) {
  return {
    type: SET_DATE_PRESET,
    payload: preset,
  };
}

export function getPreviousPayPeriodStats(id, startDate, endDate) {
  return (dispatch) => {
    dispatch(filteredStatsLoading(true));

    const query = `?start_date=${startDate}&end_date=${endDate}`;

    return fetch(`/projects/${id}/project_stats${query}`)
      .then((response) => response.json())
      .then((data) => {
        dispatch(filteredStatsLoading(false));
        dispatch(filteredStatsSuccess(data));
      })
      .catch((e) => {
        dispatch(filteredStatsLoading(false));
        dispatch(filteredStatsError(e.message));
      });
  };
}

const initialFilteredState = fromJS({
  loading: false,
  error: undefined,
  data: undefined,
});
export function filteredStats(state = initialFilteredState, action) {
  switch (action.type) {
    case FILTERED_STATS_ERROR:
      return state.set('error', action.payload);
    case FILTERED_STATS_LOADING:
      return state.set('loading', action.payload);
    case FILTERED_STATS_SUCCESS:
      return state.set('data', action.payload);
    case FILTERED_STATS_RESET:
      return initialFilteredState;
    default:
      return state;
  }
}

const initialReportFiltersState = fromJS({
  hoursByTask: true,
  hoursByPerson: false,
  billingCycle: BILLING_CYCLE_MONTHLY,
});
export function reportFilters(state = initialReportFiltersState, action) {
  switch (action.type) {
    case SET_HOURS_BY_PERSON:
      return state.set('hoursByPerson', action.payload);
    case SET_HOURS_BY_TASK:
      return state.set('hoursByTask', action.payload);
    case SET_BILLING_CYCLE:
      return state.set('billingCycle', action.payload);
    default:
      return state;
  }
}

const initialDateFiltersState = fromJS({
  startDate: moment().startOf(DATE_PRESET_MONTH).toISOString(),
  endDate: moment().endOf(DATE_PRESET_MONTH).toISOString(),
  datePreset: DATE_PRESET_MONTH,
});
export function dateFilters(state = initialDateFiltersState, action) {
  switch (action.type) {
    case SET_DATE_FILTERS:
      const { startDate, endDate } = action.payload;
      return state.set('startDate', startDate).set('endDate', endDate);
    case SET_DATE_PRESET:
      return state.set('datePreset', action.payload);
    default:
      return state;
  }
}

export default combineReducers({ filteredStats, reportFilters, dateFilters });
