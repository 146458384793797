import moment from 'moment';
import EmailHorizontalRule from 'routes/Reports/routes/BudgetSummary/components/EmailHorizontalRule';
import React from 'react';
import Percentage from 'components/Percentage/Percentage';
import Currency from 'components/Currency/Currency';
import NumberStat from 'components/NumberStat/NumberStat';
import PropTypes from 'prop-types';
import { projectShape } from 'utils/shapes/project';
import { projectStatsShape } from 'utils/shapes/stats';
import styled from 'styled-components/macro';
import BillableBudgetUsed from 'routes/Reports/routes/BudgetSummary/components/BillableBudgetUsed';
import HoursBreakdown from 'routes/Reports/routes/BudgetSummary/components/HoursBreakdown';

const PlaceholderText = styled.mark`
  //background-color: ;
`;

function EmailContent({
  project,
  overviewStats,
  filteredStats,
  filters,
  previousPayPeriodStats,
  previousPayPeriodFilters,
  reportFilters,
}) {
  const hasBudget = project && project.currentBudget > 0;
  const hasJira = project && !!project.jiraProject;
  const stats = overviewStats.data && overviewStats.data.overviewStats;

  const budgetUsedPercent = hasBudget && stats ? stats.budget_used.hours / project.currentBudget : 0;
  const budgetRemainingPercent = Math.max(0, hasBudget && stats ? 1 - budgetUsedPercent : 0);
  const projectCompletePercent =
    stats && stats.story_points_total ? stats.story_points_completed / stats.story_points_total : 0;
  const projectRemainingPercent = 1 - projectCompletePercent;

  const filterStats = filteredStats.data;
  const hoursPaidByCaxy = project && filterStats && filterStats.internallyBilledHours;
  const dollarsPaidByCaxy = project && filterStats && filterStats.internallyBilledHours * project.hourlyRate;
  const mtdStats = filterStats && filterStats.additionalStats;
  const prevStats = previousPayPeriodStats.data && previousPayPeriodStats.data.additionalStats;

  const now = moment();
  const start = moment(filters.startDate);
  let end = moment(filters.endDate);

  if (end.isAfter(now)) {
    end = now;
  }

  const { start: prevStart, end: prevEnd } = previousPayPeriodFilters;

  return (
    <div className='email-content'>
      <p>
        Hey [<PlaceholderText>Name of Person</PlaceholderText>] and All,
      </p>
      <p>Please see below for our current progress and relevant notes.</p>
      <p>
        <b>
          Our Next Planned Meeting is: [<PlaceholderText>Name of Meeting and Date</PlaceholderText>].
        </b>
      </p>
      <p>
        If you have any questions at all about this budget or the project before our next meeting, please don't hesitate
        to reach me directly. My contact info is provided below.
      </p>
      <EmailHorizontalRule />
      <h3>Project Status Information and Updates</h3>
      <h5>Current Goal</h5>
      <p>
        [{' '}
        <PlaceholderText>
          insert a note about this week’s progress toward completing all the features selected by the client for
          development, and any updates or redflags. Discuss how you are realigning expectations or priorities and
          provide any other helpful information here about what you are doing with this data you’re reporting.
        </PlaceholderText>{' '}
        ]
      </p>
      <h5>General Updates</h5>
      <h6>Work Completed Since Last Update</h6>
      <ul>
        <li>
          [<PlaceholderText>short description of completed task</PlaceholderText>]
        </li>
      </ul>
      <h6>Work Currently Underway</h6>
      <ul>
        <li>
          [<PlaceholderText>short description of task underway</PlaceholderText>]
        </li>
      </ul>
      <h6>Work Still To Do</h6>
      <ul>
        <li>
          [<PlaceholderText>short description of task to do</PlaceholderText>]
        </li>
      </ul>
      <EmailHorizontalRule />
      {stats && (
        <React.Fragment>
          <h3>Budget Snapshot</h3>

          <p>
            % Budget Used: <b>{hasBudget ? <Percentage value={budgetUsedPercent} /> : 'n/a'}</b>
            <br />% Budget Remaining:{' '}
            <b>
              {hasBudget ? (
                <React.Fragment>
                  <Percentage value={budgetRemainingPercent} /> remains
                </React.Fragment>
              ) : (
                'n/a'
              )}
            </b>
          </p>

          <p>
            % Project Complete based on current backlog of features:{' '}
            <b>
              {(hasJira && (
                <React.Fragment>
                  <Percentage value={projectCompletePercent} /> complete
                </React.Fragment>
              )) ||
                'n/a'}
            </b>
            <br />% Project Remaining based on current backlog of features:{' '}
            <b>
              {(hasJira && (
                <React.Fragment>
                  <Percentage value={projectRemainingPercent} /> remains
                </React.Fragment>
              )) ||
                'n/a'}
            </b>
          </p>

          <p>
            % Difference Between Budget Used /Project Complete:{' '}
            <b>
              {hasBudget && hasJira ? (
                <React.Fragment>
                  <Percentage value={budgetUsedPercent - projectCompletePercent} /> difference
                </React.Fragment>
              ) : (
                'n/a'
              )}
            </b>
          </p>

          <p>
            <b>
              [{' '}
              <PlaceholderText>
                insert text here about the difference between the two - whether we are on track or not and any
                suggestions about what we should do to get the numbers closer together. This is also the place to
                suggest getting additional budget, reducing the scope of the features, or pulling feature altogether.
              </PlaceholderText>{' '}
              ]
            </b>
          </p>

          <p>
            <b>
              <u>If No Feature Changes Are Possible, the current additional budget we need is $X,XXX, (XXX hours).</u>
            </b>
          </p>

          <EmailHorizontalRule />
          <h3>Additional Budget Details</h3>

          <p>
            Total Project Budget Approved To Date:{' '}
            <b>
              {hasBudget ? (
                <React.Fragment>
                  <Currency
                    value={project.currentBudget * project.hourlyRate}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />{' '}
                  approved
                </React.Fragment>
              ) : (
                'n/a'
              )}
            </b>
            <br />
            Total Project Hours Approved To Date:{' '}
            <b>
              {hasBudget ? (
                <NumberStat value={project.currentBudget} suffix={' hours approved'} maximumFractionDigits={4} />
              ) : (
                'n/a'
              )}
            </b>
          </p>

          <p>
            Current Budget Used:{' '}
            <b>
              <Currency value={stats.budget_used.dollars} minimumFractionDigits={2} maximumFractionDigits={2} /> budget
              used
            </b>
            <br />
            Current Hours Used:{' '}
            <b>
              <NumberStat value={stats.budget_used.hours} maximumFractionDigits={4} suffix={' hours used'} />
            </b>
          </p>

          {hoursPaidByCaxy && (
            <p>
              Current Hours Paid By Caxy:{' '}
              <b>
                <NumberStat value={hoursPaidByCaxy} suffix={' hours'} maximumFractionDigits={4} />
              </b>
              <br />
              Current Dollars Paid By Caxy:{' '}
              <b>
                <Currency value={dollarsPaidByCaxy} minimumFractionDigits={2} maximumFractionDigits={2} />
              </b>
            </p>
          )}

          <h5>Billable Budget Used From</h5>

          {prevStats && <BillableBudgetUsed start={prevStart} end={prevEnd} stats={prevStats} />}
          {mtdStats && <BillableBudgetUsed start={start} end={end} stats={mtdStats} />}

          {(reportFilters.hoursByPerson || reportFilters.hoursByTask) && (
            <React.Fragment>
              <h5>Hours Breakdown</h5>
              {prevStats && (
                <HoursBreakdown start={prevStart} end={prevEnd} stats={prevStats} filters={reportFilters} />
              )}
              {mtdStats && <HoursBreakdown start={start} end={end} stats={mtdStats} filters={reportFilters} />}
            </React.Fragment>
          )}

          <EmailHorizontalRule />
        </React.Fragment>
      )}
      <p>
        <b>That's all for now. Please don't hesitate to reach out with any questions.</b>
      </p>
      Best Regards,
      <br />[<PlaceholderText>Your Name</PlaceholderText>]
    </div>
  );
}

EmailContent.propTypes = {
  filters: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  project: projectShape,
  overviewStats: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.string,
    data: projectStatsShape,
  }),
  filteredStats: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.string,
    data: projectStatsShape,
  }),
  reportFilters: PropTypes.shape({
    hoursByPerson: PropTypes.bool,
    hoursByTask: PropTypes.bool,
  }),
  previousPayPeriodStats: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.string,
    data: projectStatsShape,
  }),
};

export default EmailContent;
