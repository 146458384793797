import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Callout, Cell, Colors, Grid, GridContainer } from 'react-foundation';
import ErrorMessage from 'components/ErrorMessage';
import { Link } from 'react-router-dom';
import H1 from 'components/H1';
import MainContainer from 'routes/ProjectPage/MainContainer';
import Filters from 'routes/ProjectPage/Filters';
import BillableReportDateRangeFilter from '../containers/BillableReportDateRangeFilter';
import DataTable from './DataTable';

class MonthlyBillableReport extends Component {
  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    filters: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
    data: PropTypes.array,
    getBillableReport: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    createNote: PropTypes.func.isRequired,
    updateNote: PropTypes.func.isRequired,
  };

  static buildQueryFilters(props) {
    const { startDate, endDate } = props.filters;
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');

    return { start, end };
  }

  componentDidMount() {
    const { data } = this.props;
    if (!data) {
      this.loadFilteredData();
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.filters.startDate !== this.props.filters.startDate ||
      nextProps.filters.endDate !== this.props.filters.endDate
    ) {
      this.loadFilteredData(nextProps);
    }
  }

  loadFilteredData(props) {
    if (!props) {
      props = this.props;
    }

    props.reset();

    const { start, end } = MonthlyBillableReport.buildQueryFilters(props);

    props.getBillableReport(start, end);
  }

  saveNote = (notes, rowData) => {
    const { createNote, updateNote } = this.props;
    const { start, end } = MonthlyBillableReport.buildQueryFilters(this.props);

    if (rowData.note && rowData.note.id) {
      return updateNote(rowData.note.id, { ...rowData.note, note: notes });
    } else {
      return createNote({
        employee: rowData['@id'],
        reportStartDate: start,
        reportEndDate: end,
        note: notes,
      });
    }
  };

  render() {
    const { loading, error, data } = this.props;

    return (
      <div>
        <div>
          <GridContainer>
            <H1>Monthly Billable Report - Billable By People</H1>
          </GridContainer>
          <MainContainer>
            <Filters>
              <GridContainer>
                <Grid>
                  <Cell small={10} style={{ paddingRight: '40px' }}>
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%' }}
                    >
                      <BillableReportDateRangeFilter />
                    </div>
                  </Cell>
                </Grid>
              </GridContainer>
            </Filters>
            <GridContainer>
              {loading && (
                <Callout color={Colors.SECONDARY}>
                  <p>Loading...</p>
                </Callout>
              )}
              {error && (
                <ErrorMessage
                  error={error}
                  afterContent={
                    <Link to='/' className='btn btn-default'>
                      Back to list
                    </Link>
                  }
                />
              )}
              {data && <DataTable data={data} updateData={this.saveNote} />}
            </GridContainer>
          </MainContainer>
        </div>
      </div>
    );
  }
}

export default MonthlyBillableReport;
