import React, { Component } from 'react';
import { renderToString } from 'react-dom/server';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import FontAwesome from 'react-fontawesome';
import moment from 'moment';
import debounce from 'lodash/debounce';
import ContentEditable from 'react-contenteditable';
import { Button, Callout, Cell, Colors, Grid, GridContainer, Switch } from 'react-foundation';
import ErrorMessage from 'components/ErrorMessage';
import { Link } from 'react-router-dom';
import H1 from 'components/H1';
import MainContainer from 'routes/ProjectPage/MainContainer';
import { projectShape } from 'utils/shapes/project';
import { projectEntryShape, projectStatsShape } from 'utils/shapes/stats';
import ProjectSelector from 'containers/ProjectSelector';
import {
  BILLING_CYCLE_MONTHLY,
  BILLING_CYCLE_SEMIMONTHLY,
  DATE_PRESET_MONTH,
  DATE_PRESET_SEMIMONTH,
} from 'utils/constants';
import { getPreviousSemimonthRange, getSemimonthRange } from 'utils/dateUtils';
import { IntlProvider } from 'react-intl';
import Toolbar from 'routes/ProjectPage/Toolbar';
import EmailContent from 'routes/Reports/routes/DailyRecap/components/EmailContent';
import { Field, FieldArray, reduxForm } from 'redux-form/lib/immutable';
import H3 from 'components/H3';
import TicketStatusAndTransitions from './TicketStatusAndTransitions';
import NumberStat from 'components/NumberStat';
import DailyRecapForm from './DailyRecapForm';
import styledNormalize from 'styled-normalize';

const EditableWrapper = styled.div`
  padding: 20px;
  background-color: #eeeeee;
  border: 1px solid #d9d9d9;
  border-top: none;
`;

const EditableWrapperInner = styled.div`
  background-color: #ffffff;
  box-shadow: 0 0 0 0.75pt #d1d1d1, 0 0 3pt 0.75pt #ccc;

  .email-content {
    ${styledNormalize};
    padding: 20px;

    &,
    * {
      font-family: Arial, sans-serif;
    }
  }
`;

const ReportOptionsWrapper = styled.div`
  padding: 2rem;
`;

const ListItemGrid = styled(Grid)`
  input {
    margin-bottom: 0;
  }
`;

const IconButton = styled.button`
  cursor: pointer;
  margin-left: 1em;
`;

class DailyRecap extends Component {
  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    filters: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
    project: projectShape,
    overviewStats: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.string,
      data: projectStatsShape,
    }),
    dailyRecapEntry: PropTypes.shape({
      loading: PropTypes.bool,
      saving: PropTypes.bool,
      error: PropTypes.string,
      data: projectEntryShape,
    }),
    dailyRecapStats: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.string,
      data: projectStatsShape,
    }),
    projectStats: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.string,
      data: projectStatsShape,
    }),
    previousPayPeriodStats: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.string,
      data: projectStatsShape,
    }),
    retrieve: PropTypes.func.isRequired,
    getOverviewStats: PropTypes.func.isRequired,
    createDailyRecapEntry: PropTypes.func.isRequired,
    getDailyRecapStats: PropTypes.func.isRequired,
    getPreviousPayPeriodStats: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    resetDailyRecapStats: PropTypes.func.isRequired,
    reportFilters: PropTypes.shape({
      hoursByPerson: PropTypes.bool,
      hoursByTask: PropTypes.bool,
    }),
    setHoursByTask: PropTypes.func.isRequired,
    setHoursByPerson: PropTypes.func.isRequired,
    setBillingCycle: PropTypes.func.isRequired,
    setDateFilters: PropTypes.func.isRequired,
    setDatePreset: PropTypes.func.isRequired,
    dailyRecapData: PropTypes.object,
  };

  state = {
    content: null,
    showHtmlContent: false,
  };

  contentEditable = React.createRef();

  static renderEmailContentsToString(props) {
    const { project, projectStats, reportFilters, dailyRecapStats, dailyRecapData } = props;

    return renderToString(
      project && (
        <IntlProvider locale={'en-us'}>
          <EmailContent
            project={project}
            projectStats={projectStats}
            reportFilters={reportFilters}
            dailyRecapStats={dailyRecapStats}
            dailyRecapData={dailyRecapData}
          />
        </IntlProvider>
      )
    );
  }

  static buildQueryFilters(props) {
    const projectId = decodeURIComponent(props.match.params.projectId);

    return { projectId };
  }

  componentDidMount() {
    const { project, match } = this.props;
    if (!project && match.params.projectId) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.projectId !== this.props.match.params.projectId) {
      this.loadData();
    }
  }

  onShowJiraLinksChange = (e) => {
    this.props.setShowJiraLinks(e.target.checked);
  };

  toggleHtmlContent = () => {
    const newValue = !this.state.showHtmlContent;

    this.setState({ showHtmlContent: !this.state.showHtmlContent });

    // Save a snapshot of the daily recap form whenever email content is being generated.
    if (newValue) {
      const { projectId } = DailyRecap.buildQueryFilters(this.props);

      this.props.createDailyRecapEntry({
        projectId: parseInt(projectId),
        createdAt: moment().utc().format(),
        emailContent: DailyRecap.renderEmailContentsToString(this.props),
        ...this.props.dailyRecapData,
      });
    }
  };

  loadData(props) {
    if (!props) {
      props = this.props;
    }

    props.reset();

    const filters = DailyRecap.buildQueryFilters(props);

    props.retrieve(filters.projectId);
    props.getProjectStats(filters.projectId);
    props.getOverviewStats(filters.projectId);
    this.loadDailyRecapData(props);
  }

  loadDailyRecapData(props) {
    if (!props) {
      props = this.props;
    }

    props.resetDailyRecapStats();

    const { projectId } = DailyRecap.buildQueryFilters(props);

    props.getDailyRecapStats(projectId);
  }

  renderListItemFields({ fields, meta: { touched, error } }) {
    return (
      <React.Fragment>
        <ul>
          {fields.map((listItem, index) => (
            <li key={index}>
              <ListItemGrid centerAlign>
                <Cell small={11}>
                  <Field name={listItem} component='input' type='text' />
                </Cell>
                <Cell small={1}>
                  <IconButton type='button' title='Remove' onClick={() => fields.remove(index)}>
                    <FontAwesome name='trash' size='lg' />
                  </IconButton>
                </Cell>
              </ListItemGrid>
            </li>
          ))}
          {error && <li className='error'>{error}</li>}
        </ul>
        <Button type='button' onClick={() => fields.push()}>
          Add Item
        </Button>
      </React.Fragment>
    );
  }

  render() {
    const { project, projectStats, reportFilters, dailyRecapStats } = this.props;

    const { showHtmlContent } = this.state;

    const recapData = dailyRecapStats && dailyRecapStats.data;

    const {
      blocked_in_sprint,
      completed_in_sprint,
      daily_story_point_goal,
      remaining_in_sprint,
      story_points_in_sprint,
      underway_in_sprint,
      completed_outside_sprint,
    } = (recapData && recapData.sprintStats) || {};

    return (
      <div>
        <div>
          <GridContainer>
            <ProjectSelector baseLinkUri={'/reports/daily-recap/'} label={'Select a Project'} />
            {this.props.loading ||
              (dailyRecapStats.loading && (
                <Callout color={Colors.SECONDARY}>
                  <p>Loading...</p>
                </Callout>
              ))}
            {this.props.error ||
              (dailyRecapStats.error && (
                <ErrorMessage
                  error={this.props.error || dailyRecapStats.error}
                  afterContent={
                    <Link to='/' className='btn btn-default'>
                      Back to dashboard
                    </Link>
                  }
                />
              ))}
          </GridContainer>
          {project && projectStats && (
            <React.Fragment>
              <GridContainer>
                <H1>
                  {project['name']} Daily Recap - {moment().format('MM/DD/YYYY')}
                </H1>
              </GridContainer>
              <Toolbar>
                <GridContainer>
                  Fill out the fields to generate a Daily Recap email to copy and paste into your mail client.
                </GridContainer>
              </Toolbar>
              <MainContainer>
                <GridContainer>
                  <Grid>
                    <Cell small={9}>
                      {showHtmlContent && (
                        <EditableWrapper>
                          <EditableWrapperInner>
                            <ContentEditable
                              innerRef={this.contentEditable}
                              html={DailyRecap.renderEmailContentsToString(this.props)}
                            />
                          </EditableWrapperInner>
                        </EditableWrapper>
                      )}

                      {!showHtmlContent && recapData && (
                        <DailyRecapForm
                          recapData={recapData}
                          component={this.renderListItemFields}
                          reportFilters={reportFilters}
                          completedInSprint={completed_in_sprint}
                          underwayInSprint={underway_in_sprint}
                          remainingInSprint={remaining_in_sprint}
                          blockedInSprint={blocked_in_sprint}
                          completedOutsideSprint={completed_outside_sprint}
                          dailyStoryPointGoal={daily_story_point_goal}
                          projectStats={projectStats}
                          project={project}
                        />
                      )}
                    </Cell>
                    <Cell small={3}>
                      <ReportOptionsWrapper>
                        <h4>Customize Report</h4>

                        <label htmlFor='showJiraLinks'>
                          Include links to JIRA tickets
                          <Switch
                            id='showJiraLinks'
                            input={{
                              defaultChecked: reportFilters.showJiraLinks,
                              onChange: this.onShowJiraLinksChange,
                            }}
                            active={{ text: 'Yes' }}
                            inactive={{ text: 'No' }}
                          />
                        </label>

                        <hr />
                        <Button onClick={this.toggleHtmlContent}>
                          {this.state.showHtmlContent ? 'Go back to edit form' : 'View HTML Email Content'}
                        </Button>
                      </ReportOptionsWrapper>
                    </Cell>
                  </Grid>
                </GridContainer>
              </MainContainer>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default DailyRecap;
