import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { personBreakdownShape, taskBreakdownShape } from 'utils/shapes/stats';
import HoursByTask from 'routes/Reports/routes/BudgetSummary/components/HoursByTask';
import HoursByPerson from 'routes/Reports/routes/BudgetSummary/components/HoursByPerson';

function HoursBreakdown({ start, end, stats, filters: { hoursByTask, hoursByPerson } }) {
  if (!hoursByTask && !hoursByPerson) {
    return null;
  }

  return (
    <div>
      <h6>
        <u>
          {moment(start).format('MMM. Do')} - {moment(end).format('MMM. Do')}
        </u>
      </h6>
      {hoursByTask && (
        <React.Fragment>
          <b>Hours by task:</b>
          <br />
          <HoursByTask breakdowns={stats.task_breakdowns} />
        </React.Fragment>
      )}
      {hoursByPerson && (
        <React.Fragment>
          <b>Hours by person:</b>
          <br />
          <HoursByPerson breakdowns={stats.person_breakdowns} />
        </React.Fragment>
      )}
    </div>
  );
}

HoursBreakdown.propTypes = {
  start: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  end: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  stats: PropTypes.shape({
    task_breakdowns: PropTypes.arrayOf(taskBreakdownShape),
    person_breakdowns: PropTypes.arrayOf(personBreakdownShape),
  }),
  filters: PropTypes.shape({
    hoursByPerson: PropTypes.bool,
    hoursByTask: PropTypes.bool,
    billingCycle: PropTypes.string,
  }),
};

export default HoursBreakdown;
