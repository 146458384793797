import { string, bool, shape, arrayOf, number } from 'prop-types';

export const personShape = shape({
  avatar_url: string,
  first_name: string,
  last_name: string,
});

export const userShape = shape({
  person: personShape,
});
