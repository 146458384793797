import React, { Component } from 'react';
import { renderToString } from 'react-dom/server';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import moment from 'moment';
import ContentEditable from 'react-contenteditable';
import { Button, Callout, Cell, Colors, Grid, GridContainer, Switch } from 'react-foundation';
import ErrorMessage from 'components/ErrorMessage';
import { Link } from 'react-router-dom';
import H1 from 'components/H1';
import MainContainer from 'routes/ProjectPage/MainContainer';
import { projectShape } from 'utils/shapes/project';
import { projectStatsShape } from 'utils/shapes/stats';
import ProjectSelector from 'containers/ProjectSelector';
import {
  BILLING_CYCLE_MONTHLY,
  BILLING_CYCLE_SEMIMONTHLY,
  DATE_PRESET_MONTH,
  DATE_PRESET_SEMIMONTH,
} from 'utils/constants';
import { getPreviousSemimonthRange, getSemimonthRange } from 'utils/dateUtils';
import { IntlProvider } from 'react-intl';
import Toolbar from 'routes/ProjectPage/Toolbar';
import EmailContent from 'routes/Reports/routes/BudgetSummary/components/EmailContent';

const EditableWrapper = styled.div`
  padding: 20px;
  background-color: #eeeeee;
  border: 1px solid #d9d9d9;
  border-top: none;
`;

const EditableWrapperInner = styled.div`
  background-color: #ffffff;
  box-shadow: 0 0 0 0.75pt #d1d1d1, 0 0 3pt 0.75pt #ccc;

  .email-content {
    padding: 20px;
  }
`;

const ReportOptionsWrapper = styled.div`
  padding: 2rem;
`;

class BudgetSummary extends Component {
  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    filters: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
    project: projectShape,
    overviewStats: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.string,
      data: projectStatsShape,
    }),
    filteredStats: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.string,
      data: projectStatsShape,
    }),
    previousPayPeriodStats: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.string,
      data: projectStatsShape,
    }),
    retrieve: PropTypes.func.isRequired,
    getOverviewStats: PropTypes.func.isRequired,
    getFilteredStats: PropTypes.func.isRequired,
    getPreviousPayPeriodStats: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    resetFilteredStats: PropTypes.func.isRequired,
    reportFilters: PropTypes.shape({
      hoursByPerson: PropTypes.bool,
      hoursByTask: PropTypes.bool,
    }),
    setHoursByTask: PropTypes.func.isRequired,
    setHoursByPerson: PropTypes.func.isRequired,
    setBillingCycle: PropTypes.func.isRequired,
    setDateFilters: PropTypes.func.isRequired,
    setDatePreset: PropTypes.func.isRequired,
  };

  state = {
    content: null,
  };

  contentEditable = React.createRef();

  static renderEmailContentsToString(props) {
    const { project, overviewStats, filteredStats, filters, previousPayPeriodStats, reportFilters } = props;

    return renderToString(
      project && (
        <IntlProvider locale={'en-us'}>
          <EmailContent
            project={project}
            overviewStats={overviewStats}
            filteredStats={filteredStats}
            filters={filters}
            previousPayPeriodStats={previousPayPeriodStats}
            previousPayPeriodFilters={BudgetSummary.getPreviousPayPeriodFilters({ filters, reportFilters })}
            reportFilters={reportFilters}
          />
        </IntlProvider>
      )
    );
  }

  static buildQueryFilters(props) {
    const { startDate, endDate } = props.filters;
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    const projectId = decodeURIComponent(props.match.params.projectId);

    return { projectId, start, end };
  }

  static getPreviousPayPeriodFilters({ filters, reportFilters }) {
    const { start, end } = BudgetSummary.getPreviousPayPeriod(filters.endDate, reportFilters.billingCycle);

    return { start: moment(start).format('YYYY-MM-DD'), end: moment(end).format('YYYY-MM-DD') };
  }

  static getPreviousPayPeriod(referenceDate, billingCycle) {
    let end = moment(referenceDate);
    const now = moment();

    if (end.isAfter(now)) {
      end = now;
    }

    // Calculate new date filter values.
    if (billingCycle === BILLING_CYCLE_SEMIMONTHLY) {
      const { startDate, endDate } = getPreviousSemimonthRange(end);

      return { start: startDate, end: endDate };
    }

    // Default to monthly.
    end.startOf('month').subtract(1, 'month');
    const start = end.clone();
    end.endOf('month');

    return { start: start.toISOString(), end: end.toISOString() };
  }

  static filterBilledTasks(tasks) {
    return tasks.filter((breakdown) => breakdown.task_name !== 'Internally Billed Client Work');
  }

  componentDidMount() {
    const { project, match } = this.props;
    if (!project && match.params.projectId) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.projectId !== this.props.match.params.projectId) {
      this.loadData();
    } else if (
      prevProps.filters.startDate !== this.props.filters.startDate ||
      prevProps.filters.endDate !== this.props.filters.endDate
    ) {
      this.loadFilteredData();
    }
  }

  onHoursByPersonChange = (e) => {
    this.props.setHoursByPerson(e.target.checked);
  };

  onHoursByTaskChange = (e) => {
    this.props.setHoursByTask(e.target.checked);
  };

  onBillingCycleChange = (e) => {
    const { reportFilters, filters } = this.props;
    const value = e.target.value;

    if (value !== reportFilters.billingCycle) {
      this.props.setBillingCycle(value);
      let end = moment(filters.endDate);
      const now = moment();

      if (end.isAfter(now)) {
        end = now;
      }

      const newFilters = { ...filters };
      let datePreset;

      // Calculate new date filter values.
      if (value === BILLING_CYCLE_SEMIMONTHLY) {
        const { startDate, endDate } = getSemimonthRange(end);

        newFilters.startDate = startDate;
        newFilters.endDate = endDate;
        datePreset = DATE_PRESET_MONTH;
      } else if (value === BILLING_CYCLE_MONTHLY) {
        const start = end.clone();
        start.startOf('month');
        end.endOf('month');

        newFilters.startDate = start.toISOString();
        newFilters.endDate = end.toISOString();
        datePreset = DATE_PRESET_SEMIMONTH;
      }

      this.props.setDateFilters(newFilters.startDate, newFilters.endDate);
      if (datePreset) {
        this.props.setDatePreset(datePreset);
      }
    }
  };

  onContentChange = (e) => {
    const generatedContent = BudgetSummary.renderEmailContentsToString(this.props);
    const content = e.target.value;
    if (content !== generatedContent) {
      this.setState({ content });
    }
  };

  resetContent = (e) => {
    this.setState({ content: null });
  };

  loadData(props) {
    if (!props) {
      props = this.props;
    }

    props.reset();

    const filters = BudgetSummary.buildQueryFilters(props);

    props.retrieve(filters.projectId);
    props.getOverviewStats(filters.projectId);
    this.loadFilteredData(props);
  }

  loadFilteredData(props) {
    if (!props) {
      props = this.props;
    }

    props.resetFilteredStats();

    const { projectId, start, end } = BudgetSummary.buildQueryFilters(props);

    props.getFilteredStats(projectId, start, end);

    const { start: prevStart, end: prevEnd } = BudgetSummary.getPreviousPayPeriodFilters(props);

    props.getPreviousPayPeriodStats(projectId, prevStart, prevEnd);
  }

  render() {
    const { project, reportFilters } = this.props;

    return (
      <div>
        <div>
          <GridContainer>
            <ProjectSelector baseLinkUri={'/reports/budget-summary/'} label={'Select a Project'} />
            {this.props.loading && (
              <Callout color={Colors.SECONDARY}>
                <p>Loading...</p>
              </Callout>
            )}
            {this.props.error && (
              <ErrorMessage
                error={this.props.error}
                afterContent={
                  <Link to='/' className='btn btn-default'>
                    Back to list
                  </Link>
                }
              />
            )}
          </GridContainer>
          {project && (
            <React.Fragment>
              <GridContainer>
                <H1>{project['name']} Budget Summary</H1>
              </GridContainer>
              <Toolbar>
                <GridContainer>Copy and paste the generated budget summary below into your mail client.</GridContainer>
              </Toolbar>
              <MainContainer>
                <GridContainer>
                  <Grid>
                    <Cell small={9}>
                      <EditableWrapper>
                        <EditableWrapperInner>
                          <ContentEditable
                            innerRef={this.contentEditable}
                            html={this.state.content || BudgetSummary.renderEmailContentsToString(this.props)}
                            onChange={this.onContentChange}
                          />
                        </EditableWrapperInner>
                      </EditableWrapper>
                    </Cell>
                    <Cell small={3}>
                      <ReportOptionsWrapper>
                        <h4>Customize Report</h4>
                        <label htmlFor='billing_cycle'>
                          Billing Cycle
                          <select
                            id='billing_cycle'
                            value={reportFilters.billingCycle}
                            onChange={this.onBillingCycleChange}
                          >
                            <option value={BILLING_CYCLE_MONTHLY}>Monthly</option>
                            <option value={BILLING_CYCLE_SEMIMONTHLY}>Semimonthly</option>
                          </select>
                        </label>

                        <label htmlFor='hours_by_task'>
                          Hours Breakdown By Task
                          <Switch
                            id='hours_by_task'
                            input={{
                              defaultChecked: reportFilters.hoursByTask,
                              onChange: this.onHoursByTaskChange,
                            }}
                            active={{ text: 'Yes' }}
                            inactive={{ text: 'No' }}
                          />
                        </label>

                        <label htmlFor='hours_by_person'>
                          Hours Breakdown By Person
                          <Switch
                            id='hours_by_person'
                            input={{
                              defaultChecked: reportFilters.hoursByPerson,
                              onChange: this.onHoursByPersonChange,
                            }}
                            active={{ text: 'Yes' }}
                            inactive={{ text: 'No' }}
                          />
                        </label>

                        {this.state.content && (
                          <div>
                            <hr />
                            <Button onClick={this.resetContent}>Regenerate Report</Button>
                            <p>
                              <small>
                                Note: You will lose any changes to the content when regenerating the report.
                              </small>
                            </p>
                          </div>
                        )}
                      </ReportOptionsWrapper>
                    </Cell>
                  </Grid>
                </GridContainer>
              </MainContainer>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default BudgetSummary;
