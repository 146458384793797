import { SubmissionError } from 'redux-form/immutable';
import { API_HOST, API_PATH } from '../config/_entrypoint';
import { clearAuthToken, getAuthToken, isAuthenticated } from 'utils/auth';
import { push } from 'react-router-redux';

const jsonLdMimeType = 'application/ld+json';

let reduxStore = null;

export function syncFetchWithStore(store) {
  reduxStore = store;
}

export default function (url, options = {}) {
  if ('undefined' === typeof options.headers) options.headers = new Headers();
  if (null === options.headers.get('Accept')) options.headers.set('Accept', jsonLdMimeType);

  if (
    'undefined' !== options.body &&
    !(options.body instanceof FormData) &&
    null === options.headers.get('Content-Type')
  ) {
    options.headers.set('Content-Type', jsonLdMimeType);
  }

  // Set our auth token if user is authenticated.
  if (null === options.headers.get('Authorization') && isAuthenticated()) {
    options.headers.set('Authorization', `Bearer ${getAuthToken()}`);
  }

  const link = url.includes(API_PATH) ? API_HOST + url : API_HOST + API_PATH + url;

  return fetch(link, options).then((response) => {
    if (response.ok) return response;

    // Check if this is an authorization error and we need to redirect to login.
    if (response.status === 401 && isAuthenticated()) {
      clearAuthToken();
      if (reduxStore) {
        reduxStore.dispatch(push('/login'));
      }
      const error = new Error(response.statusText);
      error.response = response;
      throw error;
    }

    return response.json().then((json) => {
      const error = json['hydra:description'] ? json['hydra:description'] : response.statusText;
      if (!json.violations) throw Error(error);

      let errors = { _error: error };
      json.violations.map((violation) => (errors[violation.propertyPath] = violation.message));

      throw new SubmissionError(errors);
    });
  });
}
