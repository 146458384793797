import ErrorMessage from 'components/ErrorMessage';
import { filteredStatsReset } from 'routes/ProjectPage/actions';
import OverviewStats from 'routes/ProjectPage/OverviewStats';
import {
  selectError,
  selectFilteredStats,
  selectFilters,
  selectLoading,
  selectOverviewStats,
  selectRetrieved,
} from 'routes/ProjectPage/selectors';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Callout, Cell, Colors, Grid, GridContainer } from 'react-foundation';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { toJS } from 'utils/toJS';
import H1 from 'components/H1';
import { projectShape } from 'utils/shapes/project';
import { projectStatsShape } from 'utils/shapes/stats';
import ProjectDateRangeFilter from 'containers/ProjectDateRangeFilter';
import ProjectSidebar from 'containers/ProjectSidebar';
import { error, getFilteredStats, getOverviewStats, loading, reset, retrieve } from 'routes/ProjectPage/actions';
import FilteredStats from 'routes/ProjectPage/FilteredStats';
import Filters from 'routes/ProjectPage/Filters';
import MainContainer from 'routes/ProjectPage/MainContainer';
import BaseSyncActionButton from 'components/SyncActionButton';
import styled from 'styled-components/macro';
import { ButtonSizes } from 'react-foundation/lib/enums';

const SyncActionButton = styled(BaseSyncActionButton)`
  margin-bottom: 0;
  margin-right: 1rem;
`;

class ProjectPage extends Component {
  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    filters: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
    retrieved: projectShape,
    overviewStats: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.string,
      data: projectStatsShape,
    }),
    filteredStats: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.string,
      data: projectStatsShape,
    }),
    retrieve: PropTypes.func.isRequired,
    getOverviewStats: PropTypes.func.isRequired,
    getFilteredStats: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    resetFilteredStats: PropTypes.func.isRequired,
  };

  static buildQueryFilters(props) {
    const { startDate, endDate } = props.filters;
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    const projectId = decodeURIComponent(props.match.params.id);

    return { projectId, start, end };
  }

  componentDidMount() {
    if (!this.props.retrieved) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      this.loadData(nextProps);
    } else if (
      nextProps.filters.startDate !== this.props.filters.startDate ||
      nextProps.filters.endDate !== this.props.filters.endDate
    ) {
      this.loadFilteredData(nextProps);
    }
  }

  loadData(props) {
    if (!props) {
      props = this.props;
    }

    props.reset();

    const filters = ProjectPage.buildQueryFilters(props);

    props.retrieve(filters.projectId);
    props.getOverviewStats(filters.projectId);
    this.loadFilteredData(props);
  }

  loadFilteredData(props) {
    if (!props) {
      props = this.props;
    }

    props.resetFilteredStats();

    const { projectId, start, end } = ProjectPage.buildQueryFilters(props);

    props.getFilteredStats(projectId, start, end);
  }

  render() {
    const { retrieved, overviewStats, filteredStats } = this.props;

    return (
      <div>
        {this.props.loading && (
          <Callout color={Colors.SECONDARY}>
            <p>Loading...</p>
          </Callout>
        )}
        {this.props.error && (
          <ErrorMessage
            error={this.props.error}
            afterContent={
              <Link to='/' className='btn btn-default'>
                Back to list
              </Link>
            }
          />
        )}

        {retrieved && (
          <div>
            <GridContainer>
              <H1>{retrieved['name']} Stats</H1>
            </GridContainer>
            <OverviewStats project={retrieved} overviewStats={overviewStats.data && overviewStats.data.overviewStats} />
            <MainContainer>
              <Filters>
                <GridContainer>
                  <Grid>
                    <Cell small={10} style={{ paddingRight: '40px' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          height: '100%',
                        }}
                      >
                        <div>
                          <SyncActionButton
                            source={'jira'}
                            projectId={retrieved && retrieved.code}
                            isHollow
                            color={Colors.SECONDARY}
                            size={ButtonSizes.SMALL}
                          >
                            Sync JIRA
                          </SyncActionButton>
                          <SyncActionButton
                            source={'harvest'}
                            projectId={retrieved && retrieved.id}
                            isHollow
                            color={Colors.SECONDARY}
                            size={ButtonSizes.SMALL}
                          >
                            Sync Harvest
                          </SyncActionButton>
                        </div>
                        <ProjectDateRangeFilter style={{ alignSelf: 'flex-end' }} />
                      </div>
                    </Cell>
                  </Grid>
                </GridContainer>
              </Filters>
              <GridContainer>
                <Grid>
                  <Cell small={10}>
                    <FilteredStats
                      project={retrieved}
                      projectStats={filteredStats.data}
                      loading={filteredStats.loading}
                      error={filteredStats.error}
                    />
                  </Cell>
                  <Cell small={2}>
                    <ProjectSidebar project={retrieved} />
                  </Cell>
                </Grid>
              </GridContainer>
            </MainContainer>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  error: selectError,
  loading: selectLoading,
  retrieved: selectRetrieved,
  filters: selectFilters,
  overviewStats: selectOverviewStats,
  filteredStats: selectFilteredStats,
});

const mapDispatchToProps = (dispatch) => {
  return {
    retrieve: (id) => dispatch(retrieve(id)),
    getOverviewStats: (id) => dispatch(getOverviewStats(id)),
    getFilteredStats: (id, start, end) => dispatch(getFilteredStats(id, start, end)),
    reset: () => {
      dispatch(reset());
      dispatch(error(null));
      dispatch(loading(false));
    },
    resetFilteredStats: () => dispatch(filteredStatsReset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(ProjectPage));
